@font-face {
font-family: '__WorkSans_2e6235';
src: url(/_next/static/media/e9173c512d048a8e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__WorkSans_2e6235';
src: url(/_next/static/media/605956ebc687da0e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__WorkSans_Fallback_2e6235';src: local("Arial");ascent-override: 82.44%;descent-override: 21.54%;line-gap-override: 0.00%;size-adjust: 112.81%
}.__className_2e6235 {font-family: '__WorkSans_2e6235', '__WorkSans_Fallback_2e6235'
}.__variable_2e6235 {--font-work-sans: '__WorkSans_2e6235', '__WorkSans_Fallback_2e6235'
}

@font-face {
font-family: '__Beausite_548ec9';
src: url(/_next/static/media/7297ce383728d8d5-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__Beausite_548ec9';
src: url(/_next/static/media/30b29f7a733a5f61-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}@font-face {font-family: '__Beausite_Fallback_548ec9';src: local("Arial");ascent-override: 92.05%;descent-override: 24.22%;line-gap-override: 0.00%;size-adjust: 103.21%
}.__className_548ec9 {font-family: '__Beausite_548ec9', '__Beausite_Fallback_548ec9'
}.__variable_548ec9 {--font-beausite: '__Beausite_548ec9', '__Beausite_Fallback_548ec9'
}

